import React, {FC, useEffect} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {IEditModalProps} from "../../interfaces/Carts";
import {useSelector} from "react-redux";
import {RootState} from "../../components/Store";
import {DDrink} from "../../defaults/Beverages";
import {DPourData} from "../../defaults/Pour";
import {addPour,updatePour} from "../../components/requests/Pour";

const EditModal: FC<IEditModalProps> = ({ show, handleClose, tableData, cartData, handleSave }) => {

  const [formData, setFormData] = React.useState(DPourData);
  const [beverage, setBeverage] = React.useState(DDrink);
  const data = useSelector((state: RootState) => state);

  useEffect(() => {

    if (tableData) {
      console.log(tableData);
      setFormData(tableData);
    }
  }, [tableData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: Number(value) });

    if(name == 'beverage_id') {

      const b = data.beverage.list.find((b: any) => b.id === Number(value));

      if (b) {

        setBeverage(b);
        setFormData(prevState => ({
          ...prevState,
          price: b.price
        }));

      }

    }


  };

  const handleSubmit = () => {

    if(formData.current_capacity == -1 || formData.current_capacity == null) {
      formData.current_capacity = formData.full_capacity;
    }

    handleSave(formData);
    handleClose();

  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Row</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Position #{formData.position}</Form.Label>
          </Form.Group>
          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Beverage</Form.Label>
            <Form.Select
                name="beverage_id"
                aria-label="Default select example"
                onChange={handleSelectChange}
                value={formData.beverage_id}>
              <option value={0}></option>
              {data.beverage.list.map((option: any, index: number) => (
                  <option key={index} value={option.id}>{option.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formJobTitle" className="mt-3">
            <Form.Label>Price pre unit</Form.Label>
            <Form.Control
              type="text"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formJobTitle" className="mt-3">
            <Form.Label>Full Capacity</Form.Label>
            <Form.Control
                type="text"
                name="full_capacity"
                value={formData.full_capacity}
                onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
