import axios from 'axios';
import {BEVERAGE_URL} from "./Beverage";
export const URL = `${process.env.REACT_APP_API_URL}/pours`;

function makeJsonForRequest(postData: any){

    return {
        name: postData.name,
        position: postData.position,
        state: postData.state || '',
        price: postData.price || 0,
        cart_id: postData.cart_id,
        unit: postData.unit ? parseFloat(postData.unit) : undefined,
        full_capacity: postData.full_capacity ? parseFloat(postData.full_capacity) : undefined,
        current_capacity: postData.current_capacity ? parseFloat(postData.current_capacity) : parseFloat(postData.full_capacity),
        beverage_id: postData.beverage_id || null
    };

}
export async function  addPour(postData: any) {
    const response = await axios.post(URL, makeJsonForRequest(postData));
    return response;
}
export async function updatePour(postData: any) {

    const response = await axios.put(URL + '/' + postData.id, makeJsonForRequest(postData));
    return response;

}
export function deleteP(postDataId: any) {

    return axios.delete(URL + '/' + postDataId, {})
        .then(response => {
            console.log(response.data);
        }).catch(error => {
            console.error(error);
        });

}
