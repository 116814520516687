/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
    className: string
    chartData: []
}

const ChartsWidget8: React.FC<Props> = ({className, chartData}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Top 10 beverage</span>

                    <span className='text-muted fw-semibold fs-7'></span>
                </h3>

                {/* begin::Toolbar */}
                <div className='card-toolbar' data-kt-buttons='true'>
                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                        id='kt_charts_widget_8_year_btn'
                    >
                        All Time
                    </a>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div
                    ref={chartRef}
                    id='kt_charts_widget_8_chart'
                    style={{height: '350px'}}
                    className='card-rounded-bottom'
                ></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {ChartsWidget8}

function getChartOptions(height: number, chartData: any): ApexOptions {

    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = '#ffffff' // Biely okraj medzi segmentmi

    const color1 = getCSSVariableValue('--kt-warning')
    const color2 = getCSSVariableValue('--kt-success')
    const color3 = getCSSVariableValue('--kt-primary')
    const color4 = getCSSVariableValue('--kt-secondary')
    const color5 = getCSSVariableValue('--kt-info')
    const color6 = getCSSVariableValue('--kt-danger')
    const color7 = getCSSVariableValue('--kt-light')
    const color8 = getCSSVariableValue('--kt-dark')
    const color9 = getCSSVariableValue('--kt-dark')
    const color10 = getCSSVariableValue('--kt-dark')

    return {
      series: chartData.qty,
      labels: chartData.beverage,
      chart: {
        height: 350,
        type: 'pie',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };
}
